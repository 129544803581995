@import "~antd/dist/antd.css";
/* @import "~swiper/swiper.css"; */
/* @import "~swiper/css/swiper.css"; */

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
a,
a:hover {
  text-decoration: none;
}
a {
  color: #3b6ecc;
  transition: color 0.2s linear;
  cursor: pointer;
}
.search-select-product, .option-product-item, 
.ant-select-item-option-content, .ant-select-selection-item {
  display: flex;
  flex-direction: row;
  align-items: center;
 
}


.search-select-product > .option-product-item >
.ant-select-item-option-content > .ant-select-selection-item > .item {
  width: 80px;
  
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-select-product > .option-product-item >
.ant-select-item-option-content > .ant-select-selection-item > .item > 
span {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.search-select-product > .option-product-item >
.ant-select-item-option-content > .ant-select-selection-item > .item ,
.name {
  width: 110px!important;
}


.boxNameFox {
  width: 200px;
}
.ant-table {
  font-size: 13px;
}

.ant-layout-header {
  padding-right: 2rem;
  padding-left: 2rem;
  height: 49px;
  line-height: normal;
  background-color: #f7f9fc;
  user-select: none; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.emall-card {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.08);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  min-width: 250px; 
}


.emall-card  > .header {
  padding: 10px 15px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
}
.emall-card > .body {
  padding: 15px;
}


.emall-card  > .header  > h2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 17px;
  padding: 0;
  margin: 0;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -.5rem;
  margin-left: -.5rem;
}
.product-item {
  position: relative;
  width: 100%; 
  padding-right: .5rem;
  padding-left: .5rem;
}
.ant-message-notice > .anticon {
  display: inline-flex;
}


.emall-product-modal {
  width: 1100px!important;
} 
 


.emall-product-modal  > .ant-modal-close {
  margin-right: 5px;
}
.emall-product-modal  > button {
  outline: none!important;
}
.emall-product-modal  > .ant-modal-body {
  padding: 45px 10px 30px 10px;
 
}

.emall-product-modal  > .ant-modal-body >  .ant-tabs-nav {
  margin: 0 15px;
}


.emall-product-modal  > .ant-modal-body >  .ant-tabs-nav  > .ant-tabs-tab {
  color: #333!important;
  margin-bottom: 5px;
}
.emall-product-modal  > .ant-modal-body >  .ant-tabs-nav  > .ant-tabs-tab-active {
  font-weight: 500;
}
.emall-product-modal  > .ant-modal-body >  .ant-tabs-nav  > .ant-tabs-ink-bar {
  background: #ff5e05;
  height: 3px;
}



.ant-card-hoverable.active {
  border-color: transparent;
  box-shadow: "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)";
  border: 2px solid #2196f3;
}
@media (min-width: 390px) {
  .product-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 576px) {
  .product-item {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; 
  }
}
@media (min-width: 701px) { 
  .product-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
} 
@media (min-width: 994px) { 
  .product-item {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; 
  }
} 
@media (min-width: 1400px) {
  .product-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}